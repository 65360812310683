import { ForwardedRef, forwardRef } from 'react';

import { palette } from '../../../theme';
import { Asterisk } from '../../Asterisk';
import { Icon, IconProperties } from '../../Icon';

import {
    StyledTextInput,
    StyledTextInputContainer,
    customStyles,
    StyledErrorMessage,
    StyledLabel,
} from './TextInput.styled';
import { TextInputProperties } from './TextInput.types';

const SelectIcon = (properties: Omit<IconProperties, 'name'>) => (
    <Icon {...properties} name="chevronDown" />
);

export const TextInput = forwardRef(
    (
        properties: TextInputProperties,
        reference: ForwardedRef<HTMLInputElement>,
    ) => {
        const {
            iconElementRight,
            iconElementLeft,
            variant = 'white',
            errorMessage,
            isInvalid,
            value,
            label,
            select,
            multiline,
            minRows = 4,
            className,
            ...otherProperties
        } = properties;

        const defaultIconProperties = {
            color: palette.primary.dark,
            size: 24,
        };

        const override = customStyles();
        const inputVariant = otherProperties.disabled ? 'grey' : variant;
        return (
            <>
                {label && (
                    <StyledLabel
                        htmlFor={otherProperties.id}
                        $variant={inputVariant}
                        data-testid="textInputLabel"
                    >
                        {label}{' '}
                        {otherProperties.required && (
                            <Asterisk color={palette.common.blue} />
                        )}
                    </StyledLabel>
                )}
                <StyledTextInputContainer
                    $variant={inputVariant}
                    $isInvalid={isInvalid}
                    $multiline={multiline}
                    data-testid="textInputContainer"
                    className={className}
                >
                    {iconElementLeft && (
                        <span
                            {...{
                                tabIndex: iconElementLeft.onClick ? 0 : -1,
                            }}
                            role={
                                iconElementLeft.onClick ? 'button' : undefined
                            }
                            aria-label={
                                iconElementLeft.onClick
                                    ? iconElementLeft.accessibleTitle ||
                                      iconElementLeft.name
                                    : undefined
                            }
                            data-testid="leftIconContainer"
                        >
                            <Icon
                                accessibleTitle={iconElementLeft.name}
                                {...defaultIconProperties}
                                {...iconElementLeft}
                            />
                        </span>
                    )}
                    <StyledTextInput
                        {...otherProperties}
                        inputProps={{
                            // eslint-disable-next-line react/destructuring-assignment
                            'aria-label': properties['aria-label'] || 'input',
                            ...otherProperties.inputProps,
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                            classes: {
                                root: override.formControl,
                                input: override.input,
                            },
                        }}
                        SelectProps={{
                            classes: {
                                root: override.formControl,
                            },
                            native: true,
                            IconComponent: SelectIcon,
                        }}
                        type={properties.type || 'text'}
                        variant="outlined"
                        inputRef={reference}
                        value={value}
                        select={select}
                        multiline={multiline}
                        minRows={minRows}
                    />
                    {iconElementRight && !isInvalid && !select && (
                        <span
                            {...{
                                tabIndex: iconElementRight.onClick ? 0 : -1,
                            }}
                            role={
                                iconElementRight.onClick ? 'button' : undefined
                            }
                            aria-label={
                                iconElementRight.onClick
                                    ? iconElementRight.accessibleTitle ||
                                      iconElementRight.name
                                    : undefined
                            }
                            onKeyDown={iconElementRight.onKeyDown}
                            data-testid="rightIconContainer"
                        >
                            <Icon
                                accessibleTitle={iconElementRight.name}
                                {...defaultIconProperties}
                                {...iconElementRight}
                            />
                        </span>
                    )}
                    {isInvalid && (
                        <Icon
                            name="information"
                            color={palette.error.main}
                            size={24}
                        />
                    )}
                </StyledTextInputContainer>

                {errorMessage && (
                    <StyledErrorMessage aria-label="error message" role="alert">
                        {errorMessage}
                    </StyledErrorMessage>
                )}
            </>
        );
    },
);

TextInput.displayName = 'TextInput';

export default TextInput;
